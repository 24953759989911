
import Header from './Header'; // Adjust the path as needed
import Showtimes1 from './Showtimes1';
import StickyBarLeft from './StickBarLeft';
import StickyBarRight from './StickyBarRight';



const Showtimes = () => {
    // Inline styles for the container
    const appContainerStyle = {
      textAlign: 'center',
      backgroundColor: '#001f3f', // Dark Navy Blue
      color: 'white',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    };
  
    // Inline styles for the main content
  
    return (
      <div style={appContainerStyle}>
       <Showtimes1/>
      </div>
    );
  }
  
  export default Showtimes;
  