import React from 'react';
import './NowPlaying.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';


const movies = [
  { image: 'https://www.movieposters.com/cdn/shop/files/despicable_me_four_477x.jpg?v=1708104714', title: 'Dispicable Me 4', runtime: '120 min', rating: 'PG-13' },
  { image: 'https://flash.sonypictures.com/affirmfilms/theforge/TheForge-Poster.jpg', title: 'The Forge', runtime: '110 min', rating: 'PG' },
  { image: 'https://m.media-amazon.com/images/M/MV5BZWI1ZDY1YTQtMjRkNy00ZDZhLWE3OTItMTIwNzliY2Y1MTZhXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg', title: 'Transformers One', runtime: '130 min', rating: 'R' },
  { image: 'https://s3.amazonaws.com/nightjarprod/content/uploads/sites/261/2024/08/05154755/b33nnKl1GSFbao4l3fZDDqsMx0F-scaled.jpg', title: 'Alien Romulus', runtime: '140 min', rating: 'R' },
  { image: 'https://www.movieposters.com/cdn/shop/files/blink_twice_grande.jpg?v=1718830090', title: 'Blink Twice', runtime: '90 min', rating: 'R' },
  { image: 'https://upload.wikimedia.org/wikipedia/en/thumb/f/f9/Strange_Darling_poster.jpg/220px-Strange_Darling_poster.jpg', title: 'Strange Darlings', runtime: '100 min', rating: 'R' },
];

const NowPlaying = () => {
  return (
    <div className="nowplaying-container">
      <div className="background-image">
        {/* The image background */}
        <img src="https://www.fairobserver.com/wp-content/uploads/2023/05/AI.jpg" alt="Background" className="background-img" />
      </div>
      <div className="movies-list">
        {movies.map((movie, index) => (
          <div key={index} className="movie-item">
            <img src={movie.image} alt={movie.title} className="movie-image" />
            <div className="movie-details">
              <h3 className="movie-title">{movie.title}</h3>
              <p className="movie-runtime-rating">{movie.runtime} • {movie.rating}</p>
              <Link to="/Showtimes" className="get-tickets-button">Get Tickets</Link>
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NowPlaying;
