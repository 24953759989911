import React from 'react';
//eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';

import Showtimes from './components/Showtimes'; // Import your Showtimes component
import Home from './components/screens/Home';
import Cashflow from './components/Cashflow'
import TheaterRoom from './components/TheaterRoom';
import Success from './components/Success';


const App = () => {


  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/Showtimes" element={<Showtimes/>}/>
        <Route path="/Cashflow" element={<Cashflow/>}/>
        <Route path="/TheaterRoom" element={<TheaterRoom/>}/>
        <Route path="/Success" element={<Success/>}/>

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
