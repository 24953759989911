import React, { useState, useEffect } from 'react';
import './slideshow.css'; // Import the CSS file for styling

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const desktopImages = [
    'https://vids-starviewcinema.s3.us-east-2.amazonaws.com/Untitled+design.png.jpg',
    'https://movies.sterkinekor.co.za/CDN/media/entity/get/FilmTitleGraphic/HO00003127?referenceScheme=HeadOffice&allowPlaceHolder=true',
    'https://news.tfw2005.com/wp-content/uploads/sites/10/2024/05/Transformers-One-Poste-HQr-1278x665.jpg',
    'https://i.ytimg.com/vi/oKVHf1T83wA/maxresdefault.jpg',
    'https://www.equip.org/wp-content/uploads/2024/08/Design-4.png',
  ];

  const mobileImages = [
    'https://miro.medium.com/v2/resize:fit:1200/1*Czg8jFdD3nZbH6OQjWKlFQ.jpeg',
    'https://image.tmdb.org/t/p/w300_and_h450_bestv2/lZGOK0I2DJSRlEPNOAFTSNxSjDD.jpg',
    'https://i0.wp.com/empoweratlantamagazine.com/wp-content/uploads/2024/07/5c98936b-3d06-4ef0-b9c8-c5395db35678-2-1.png?fit=1080%2C1080&ssl=1',
    'https://upload.wikimedia.org/wikipedia/en/thumb/f/f9/Strange_Darling_poster.jpg/220px-Strange_Darling_poster.jpg" alt="Strange Darling - Wikipedia',
    'https://m.media-amazon.com/images/M/MV5BMDU0NjcwOGQtNjNjOS00NzQ3LWIwM2YtYWVmODZjMzQzN2ExXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg',
  ];

  const images = isMobile ? mobileImages : desktopImages;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5 seconds delay

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(interval);
    };
  }, [images.length]);

  return (
    <div className="slideshow-container">
      <div
        className="slideshow-slide"
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      />
    </div>
  );
};

export default Slideshow;
