import React from 'react';
import './Holiday.css';

const HolidayPromo = () => {
  return (
    <div className="holiday-container">
    
    <div className="trailer-frame">
  <video 
    src="https://vids-starviewcinema.s3.us-east-2.amazonaws.com/Untitled+design+(2).mp4"  
    className="trailer-video"  
    autoPlay
    loop
    shouldPlay
  ></video>
</div>
    </div>
  );
};

export default HolidayPromo;
