import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const Header = () => {
  const [button, setButton] = useState(true);
  const [dropdown, setDropdown] = useState({ show1: false, show2: false, show3: false });
  const [click, setClick] = useState(false);

  const handleDropdownToggle = (index) => {
    setDropdown((prevState) => {
      const newState = {
        show1: false,
        show2: false,
        show3: false,
      };
      newState[`show${index}`] = !prevState[`show${index}`];
      return newState;
    });
  };
  

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
    return () => window.removeEventListener('resize', showButton);
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-container">
        <Link to='/Home' className='navbar-logo'>StarView Cinema</Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='#' className='nav-links' onClick={() => handleDropdownToggle(1)}>
              See A Movie
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='#' className='nav-links' onClick={() => handleDropdownToggle(2)}>
              Food & Drinks
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='#' className='nav-links' onClick={() => handleDropdownToggle(3)}>
              Find A Theater
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/' className='nav-links-mobile' onClick={closeMobileMenu}>
              STARVIEW X LOGIN
            </Link>
          </li>
        </ul>
        {button && <Button buttonStyle='btn--outline'>SHOWTIMES</Button>}
      </div>

      <div className={click ? 'secondary-header active' : 'secondary-header'}>
      <Link to="/Showtimes" className="get-tickets">Get Tickets</Link>
      <div className="header-links">
          <a href="#merchandise">Merchandise</a>
          <a href="#gift-cards">Gift Cards</a>
          <a href="#offers">Offers</a>
          <a href="#discounts">Discounts</a>
        </div>
      </div>

      {dropdown.show1 && <DropdownMenu menuIndex={1} />}
      {dropdown.show2 && <DropdownMenu menuIndex={2} />}
      {dropdown.show3 && <DropdownMenu menuIndex={3} />}
    </div>
  );
};

const DropdownMenu = ({ menuIndex }) => {
  const menuContent = [
    {
      image: 'https://movieswithmark.com/wp-content/uploads/2024/07/DespicableMe4.jpg',
      categories: [
        { title: 'Movies @ StarView Cinema', items: ['Dispicable Me 4 3D', 'Blink Twice', 'Alien Romulus', 'Transformers One', 'Strange Darling', 'Mavka', 'See All'] },
        { title: 'Special Features', items: ['VR Exclusive', 'Apple Theater', 'Mobile Experience', 'Restriction Policies', 'Questions and Concerns', 'Customer Support', 'Advanced Technology'] },
        { title: 'StarView X', items: ['Member Benefits', 'Exclusive Items', 'Early Access', 'Redeem Your Points', 'Win A Trip - Sweepstakes', 'Veterans Watch', 'First Response Experience'] }
      ]
    },
    {
      image: 'https://static.vecteezy.com/system/resources/thumbnails/010/872/508/small/3d-deliveryman-looking-at-delivery-location-through-smartphone-png.png',
      categories: [
        { title: 'MEET ORDERUP', items: ['Delivery Made Simple', 'Refund Request', 'Whats Near You', 'International Pickup'] },
        { title: 'FAST DELIVERY', items: ['Drivers Available', 'International Stores', 'International Delivery'] },
        { title: 'AFFORDABLE', items: [] }
      ]
    },
    {
      image: 'https://www.student-circuit.com/wp-content/uploads/sites/54/2023/07/AdobeStock_583622105.jpeg',
      categories: [
        { title: 'STAVIEW X THEATERS', items: ['STARVIEW X PHOENIX', 'STARVIEW X BULLHEAD CITY', 'STARVIEW X MESA', 'STARVIEW X TAMPA', 'STARVIEW X SALT LAKE CITY', 'STARVIEW X LAS VEGAS', 'STARVIEW X NEW YORK'] },
        { title: 'OVER THE SEA', items: ['STARVIEW X UKRAINE', 'STARVIEW X UK', 'STARVIEW X MEXICO', 'STARVIEW X AFRICA', 'STARVIEW X CHINA', 'STARVIEW X JAPAN', 'STARVIEW X AUS'] },
        { title: 'OVER THE SEA', items: ['STARVIEW X SOUTH KOREA', 'STARVIEW X NORTH KOREA', 'STARVIEW X CANADA', 'STARVIEW X BRAZIL', 'STARVIEW X GERMANY', 'STARVIEW X INDIA', 'STARVIEW X SWEDEN'] }
      ]
    }
  ];

  const { image, categories } = menuContent[menuIndex - 1];

  return (
    <div className="dropdown-menu">
      <a href="#now-playing" className="now-playing-link">
        <img src={image} alt="Now Playing" className="now-playing-image" />
      </a>
      {categories.map((category, index) => (
        <div className="category-container" key={index}>
          <a href={`#category-${index}`} className="category-link">{category.title}</a>
          <ul id={`category-${index}`} className="category-list">
            {category.items.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Header;
