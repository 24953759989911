import React from 'react';
import './StickyBarRight.css'; // Ensure the path is correct for your CSS file

const StickyBarRight = () => {
  return (
    <div className="sticky-bar-right">
      <video 
        src="https://vids-starviewcinema.s3.us-east-2.amazonaws.com/videoplayback.mp4" 
        className="trailer-video-background" 
        autoPlay 
        loop 
        muted
        
      ></video>
      <div className="sticky-bar-content">
        {/* Your content goes here */}
      </div>
    </div>
  );
};

export default StickyBarRight;
