
import Header from '../../components/Header'; // Adjust the path as needed
import Slideshow from '../../components/slideshow';
import NowPlaying from '../../components/NowPlaying';
import HolidayPromo from '../../components/Holiday';
import Footer from '../../components/Footer';


const Home = () => {
    // Inline styles for the container
    const appContainerStyle = {
      textAlign: 'center',
      backgroundColor: '#001f3f', // Dark Navy Blue
      color: 'white',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    };
  
    // Inline styles for the main content
  
    return (
      <div style={appContainerStyle}>
        <Header />
        <Slideshow/>
        <NowPlaying/>
        <HolidayPromo/>
        <Footer/>
      </div>
    );
  }
  
  export default Home;
  