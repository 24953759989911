import React from 'react';
import './footer.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section">
        <div className="footer-column">
          <h4 className="footer-heading">Our Company</h4>
          <ul className="footer-list">
            <li>Our Brands</li>
            <li>Contact Us</li>
            <li>FAQs</li>
            <li>Corporate Information</li>
            <li>StarView Cinema  Investor Connect</li>
            <Link to="/Cashflow">Investor Relations</Link> {/* Use Link for navigation */}
            <li>Media Center</li>
            <li>Careers</li>
            <li>Associate Portal</li>
            <li>Vulnerability Reporting</li>
            <li>California Collection Notice</li>
            <li>StarView Cinema Privacy Policy</li>
            <li>Terms & Conditions</li>
            <li>StarView Cinema Terms & Conditions</li>
            <li>StarView Cinema Terms & Conditions</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4 className="footer-heading">Movies</h4>
          <ul className="footer-list">
            <li>Movies</li>
            <li>Theatres</li>
            <li>Ratings Information</li>
            <li>IMAX at StarView Cinema</li>
            <li>Dolby Cinema at StarView Cinema</li>
            <li>3D at StarViewCinema</li>
            <li>Apple Vision StarView Cinema</li>
            <li>VR StarView Cinema</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4 className="footer-heading">Programming</h4>
          <ul className="footer-list">
            <li>Private Theatre Rentals</li>
            <li>StarView International</li>
            <li>StarView Countries</li>
            <li>StarView History</li>
            <li>Fan Faves</li>
            <li>StarViewX</li>
            <li>Film Festivals</li>
            <li>Special Events</li>
            <li>Fathom Events</li>
            <li>Exclusive Events </li>
            <li>Kids At StarView X</li>
            <li>Groups & Events</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4 className="footer-heading">More</h4>
          <ul className="footer-list">
            <li>StarView X Member</li>
            <li>Offers & Promotions</li>
            <li>Gift Cards</li>
            <li>Movie Merchandise</li>
            <li>StarView X Merchandise</li>
            <li>NFTs from StarView X</li>
            <li>Mobile App</li>
            <li>StarView X Scene</li>
            <li>Ufilm Academy</li>
            <li>Creaative Artist</li>
            <li>Photosensitivity Notice</li>
            <li>Request Refund</li>
            <li>Business Clients</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© Copyright 2024 StarView Cinema</p>
      </div>
    </footer>
  );
};

export default Footer;
