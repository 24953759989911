 import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';

const Success = () => {
  const location = useLocation();
  const { movie, quantity } = location.state || {}; // Retrieve state from location
  const [ticketLinks, setTicketLinks] = useState([]);
  const [qrCodes, setQrCodes] = useState([]);
  
  useEffect(() => {
    // Trigger TTS
    
    // Generate unique links and QR codes
    generateTicketLinks(quantity);
  }, [quantity]);

  const generateTicketLinks = (quantity) => {
    // Example of generating unique ticket links
    const links = [];
    const qrCodes = [];
    for (let i = 0; i < quantity; i++) {
      const uniqueId = generateUniqueId();
      const link = `https://yourapp.com/ticket/${uniqueId}`;
      links.push(link);
      qrCodes.push(link);
    }
    setTicketLinks(links);
    setQrCodes(qrCodes);
  };

  const generateUniqueId = () => {
    // Simple unique ID generation function
    return Math.random().toString(36).substr(2, 9);
  };

  return (
    <div style={styles.page}>
      <Confetti />
      <div style={styles.container}>
        <h1 style={styles.title}>Congrats on Your Purchase!</h1>
        <p style={styles.subtitle}>Here are your tickets:</p>
        {ticketLinks.length > 0 && (
          <div style={styles.linksContainer}>
            {ticketLinks.map((link, index) => (
              <div key={index} style={styles.ticketLink}>
                <a href={link} target="_blank" rel="noopener noreferrer" style={styles.link}>
                  Ticket {index + 1}
                </a>
                <div style={styles.qrCode}>
                  <QRCode value={link} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  page: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    position: 'relative',
  },
  container: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ticketLink: {
    margin: '10px 0',
  },
  link: {
    fontSize: '16px',
    color: '#007bff',
    textDecoration: 'none',
  },
  qrCode: {
    marginTop: '10px',
  },
};

export default Success;
