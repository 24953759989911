import React from 'react';
import './StickyBarLeft.css'; // Ensure the path is correct for your CSS file

const StickyBarLeft = () => {
  return (
    <div className="sticky-bar-left">
      <div className="sticky-bar-content">
        <img src="https://preview.redd.it/new-poster-for-alien-romulus-v0-cd92l4whqd4d1.jpeg?auto=webp&s=4101b6cd347899470f5c22fe94b738ee5e747bd8" alt="Sidebar" className="sticky-bar-image" />
      </div>
    </div>
  );
};

export default StickyBarLeft;
