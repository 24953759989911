import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function TheaterRoom() {
  const location = useLocation();
  const { movie: selectedMovie, time: selectedTime } = location.state || {}; // Destructure state to get movie and time
  const [quantity, setQuantity] = useState(1); // State to manage ticket quantity

  useEffect(() => {
    const button = document.querySelector('button');
    button.addEventListener("click", handleCheckout);

    return () => {
      button.removeEventListener("click", handleCheckout);
    };
  }, [quantity]);

  const handleCheckout = () => {
    fetch('https://gateway-pay-1.onrender.com/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [{ id: 1, quantity: quantity }],
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  return (
    <div style={styles.page}>
      <div style={styles.container}>
        <h1 style={styles.title}>{selectedMovie ? selectedMovie.name : 'Movie'}</h1> {/* Display movie title */}
        <p style={styles.time}>{selectedTime || 'Time not selected'}</p> {/* Display selected time */}

        <div style={styles.quantityContainer}>
          <label style={styles.label} htmlFor="quantity">Tickets:</label>
          <input
            id="quantity"
            type="number"
            min="1"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            style={styles.input}
          />
        </div>

        <button style={styles.button} onClick={handleCheckout}>CHECKOUT</button> {/* Make sure to attach click handler */}
      </div>
    </div>
  );
}

const styles = {
  page: {
    backgroundColor: '#001f3f',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent glass effect
    backdropFilter: 'blur(10px)', // Apply blur to enhance glassmorphism effect
    padding: '30px',
    borderRadius: '12px',
    textAlign: 'center',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  title: {
    fontSize: '24px',
    color: 'white',
    marginBottom: '10px',
  },
  time: {
    fontSize: '18px',
    color: '#7FDBFF',
    marginBottom: '20px',
  },
  quantityContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  label: {
    marginRight: '10px',
    fontSize: '18px',
    color: 'white',
  },
  input: {
    width: '60px',
    fontSize: '18px',
    padding: '5px',
    borderRadius: '4px',
    border: 'none',
    textAlign: 'center',
  },
  button: {
    background: 'linear-gradient(90deg, #6a0dad, #7FDBFF)',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  },
};
