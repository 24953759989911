import React, { useState, useEffect } from 'react';
import Header from './Header';
import '../components/screens/Showtimes.css';
import StickyBarLeft from './StickBarLeft';
import StickyBarRight from './StickyBarRight';
import { useNavigate } from 'react-router-dom';

const Showtimes1 = () => {
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate();
  const [availability, setAvailability] = useState({});


   const showtimesData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": "1",
        "item": {
          "@type": "Movie",
          "name": "Despicable Me 4",
          "url": "https://www.lukeworthsholdings.com",
          "image": "https://www.movieposters.com/cdn/shop/files/despicable_me_four_477x.jpg?v=1708104714",
          "trailer": "https://www.youtube.com/embed/LtNYaH61dXY",
          "dateCreated": "2024-08-29",
          "director": {
            "@type": "Person",
            "name": "Chris Renaud"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "90",
            "bestRating": "100",
            "ratingCount": "5000"
          },
          "event": [
            {
              "@type": "Event",
              "name": "Despicable Me 4 Screening",
              "startDate": "2024-08-29T10:00:00",
              "endDate": "2024-08-29T11:30:00",
              "location": {
                "@type": "Place",
                "name": "StarView Cinema",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "3699 AZ-95",
                  "addressLocality": "Bullhead City",
                  "postalCode": "86442",
                  "addressCountry": "US"
                }
              }
            }
          ]
        }
      },
      {
        "@type": "ListItem",
        "position": "2",
        "item": {
          "@type": "Movie",
          "name": "Alien Romulus",
          "url": "https://www.marvel.com",
          "image": "https://m.media-amazon.com/images/M/MV5BMDU0NjcwOGQtNjNjOS00NzQ3LWIwM2YtYWVmODZjMzQzN2ExXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg",
          "trailer": "https://www.youtube.com/watch?v=OzY2r2JXsDM",
          "dateCreated": "2024-09-01",
          "director": {
            "@type": "Person",
            "name": "Nia DaCosta"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "85",
            "bestRating": "100",
            "ratingCount": "1500"
          },
          "event": [
            {
              "@type": "Event",
              "name": "The Alien Romulus",
              "startDate": "2024-09-01T14:00:00",
              "endDate": "2024-09-01T16:00:00",
              "location": {
                "@type": "Place",
                "name": "Galaxy Theater",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "3699 AZ-95",
                  "addressLocality": "Bullhead City",
                  "postalCode": "86442",
                  "addressCountry": "US"
                }
              }
            }
          ]
        }
      },
      {
        "@type": "ListItem",
        "position": "3",
        "item": {
          "@type": "Movie",
          "name": "Blink Twice",
          "url": "https://www.blinktwicemovie.com",
          "image": "https://upload.wikimedia.org/wikipedia/en/5/56/Blink_Twice_poster.jpeg",
          "dateCreated": "2024-12-15",
          "director": {
            "@type": "Person",
            "name": "Zoe Kravitz"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "92",
            "bestRating": "100",
            "ratingCount": "8000"
          },
          "event": [
            {
              "@type": "Event",
              "name": "Blink Twice",
              "startDate": "2024-12-15T17:00:00",
              "endDate": "2024-12-15T20:00:00",
              "location": {
                "@type": "Place",
                "name": "IMAX Theater",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "3699 AZ-95",
                  "addressLocality": "Bullhead City",
                  "postalCode": "86442",
                  "addressCountry": "US"
                }
              }
            }
          ]
        }
      },
      {
        "@type": "ListItem",
        "position": "4",
        "item": {
          "@type": "Movie",
          "name": "Strange Darling",
          "url": "https://www.strangedarling.com",
          "image": "https://upload.wikimedia.org/wikipedia/en/thumb/f/f9/Strange_Darling_poster.jpg/220px-Strange_Darling_poster.jpg",
          "dateCreated": "2024-11-01",
          "director": {
            "@type": "Person",
            "name": "Denis Villeneuve"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "88",
            "bestRating": "100",
            "ratingCount": "2000"
          },
          "event": [
            {
              "@type": "Event",
              "name": "Strange Darling Screening",
              "startDate": "2024-11-01T19:00:00",
              "endDate": "2024-11-01T22:00:00",
              "location": {
                "@type": "Place",
                "name": "Cineplex Theater",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "3699 AZ-95",
                  "addressLocality": "Bullhead City",
                  "postalCode": "86442",
                  "addressCountry": "US"
                }
              }
            }
          ]
        }
      },
      {
        "@type": "ListItem",
        "position": "5",
        "item": {
          "@type": "Movie",
          "name": "Transformers One",
          "url": "https://www.transformersone.com",
          "image": "https://resizing.flixster.com/Nf9rlbQUjzuMQ-vXdRGMScWkcs8=/ems.cHJkLWVtcy1hc3NldHMvbW92aWVzLzU4MDZhYjI1LTFiNTQtNDc0Ni04OTI2LWI0Nzc4OWJiNDYxYy5qcGc=",
          "dateCreated": "2024-10-15",
          "director": {
            "@type": "Person",
            "name": "Andy Muschietti"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "80",
            "bestRating": "100",
            "ratingCount": "3000"
          },
          "event": [
            {
              "@type": "Event",
              "name": "Transformers One Screening",
              "startDate": "2024-10-15T20:00:00",
              "endDate": "2024-10-15T22:30:00",
              "location": {
                "@type": "Place",
                "name": "Metroplex Cinema",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "3699 AZ-95",
                  "addressLocality": "Bullhead City",
                  "postalCode": "86442",
                  "addressCountry": "US"
                }
              }
            }
          ]
        }
      },
    ]
  };
  useEffect(() => {
    const intervals = [];
    const times = generateTimeSlots();

    times.forEach((time, index) => {
      const interval = setInterval(() => {
        setAvailability(prev => {
          const updated = { ...prev };
          const timeSlot = `slot_${index}`;
          if (updated[timeSlot] > 0) {
            updated[timeSlot]--;
          } else {
            updated[timeSlot] = 100;
          }
          return updated;
        });
      }, (index + 1) * 5000); // Adjust interval timing per slot
      intervals.push(interval);
    });

    return () => intervals.forEach(clearInterval);
  }, []);

  const generateTimeSlots = () => {
    const slots = [];
    let startTime = new Date();
    startTime.setHours(10, 0, 0, 0);

    for (let i = 0; i < 12; i++) {
      slots.push(startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
      startTime.setHours(startTime.getHours() + 1);
    }

    return slots;
  };

  return (
    <div>
      <Header />
      <div className="showtimes-container">
        <StickyBarLeft />
        <div className="main-content">
          <h1>Showtimes</h1>
          {showtimesData.itemListElement.map((item) => (
            <div key={item.position} className="movie-card">
              <img src={item.item.image} alt={item.item.name} className="movie-poster" />
              <h2>{item.item.name}</h2>
              <button onClick={() => setSelectedMovie(item.item)}>View Showtime</button>
            </div>
          ))}
        </div>
        <StickyBarRight />
      </div>

      {selectedMovie && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setSelectedMovie(null)}>Close</button>
            <h2>{selectedMovie.name}</h2>
            <iframe
              src={selectedMovie.trailer}
              title="Trailer"
              frameBorder="0"
              allowFullScreen
              autoPlay
              className="trailer-video"
            ></iframe>
            <div className="time-slots">
              {generateTimeSlots().map((time, index) => {
                const slotKey = `slot_${index}`;
                return (
                  <div
                    key={index}
                    className={`time-slot ${availability[slotKey] === 0 ? 'unavailable' : ''}`}
                    onClick={() => {
                      if (availability[slotKey] > 0) {
                        setSelectedTime(time);
                        navigate('/TheaterRoom', { state: { movie: selectedMovie, time: time } });
                      }
                    }}
                  >
                    {time}
                    <div className="availability">
                      {availability[`slot_${index}`] || 100} seats available
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Showtimes1;
